import React from 'react';
import { graphql } from 'gatsby'; 
import Img from 'gatsby-image';

import "@reach/dialog/styles.css"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import "./single-photo-gallery.scss";
import Tags from '../../components/tags';
import SimpleReactLightbox from "simple-react-lightbox"; 
import { SRLWrapper } from "simple-react-lightbox"
import { GatsbyImage } from 'gatsby-plugin-image'

const SinglePhotoGallery = ({ data }) => {
    const post = data.markdownRemark;

    return (
        <Layout>
          <SEO/>
          <div className="single-photo-gallery-wrapper">
            <h1>{post.frontmatter.title}</h1>
            <Tags tags={post.frontmatter.tags}/>
            <div className="article" dangerouslySetInnerHTML={{__html: post.html}}/>
              <div className="image-container">
                <SimpleReactLightbox>
                  <SRLWrapper>
                  <div className="image-grid">
                    {data.allFile.edges.map((image, key) => (
                        <GatsbyImage 
                          key={key}
                          className="image-item"
                          alt={image.node.base.split(".")[0]} 
                          image={image.node.childImageSharp.gatsbyImageData}
                        />
                    ))}
                    </div>
                    </SRLWrapper>
                  </SimpleReactLightbox>              
            </div>
          </div>
        </Layout>
      
    )
}

export const photoQueryQQ = graphql`
  query photoQueryQQ($slug: String, $directory: String) {
    markdownRemark(fields: { slug: { eq: $slug } } ) {
      id
      html
      frontmatter {
        title
        author
        date(formatString: "MMM Do YYYY")
        directory
        tags
      }
    }
    allFile(
      filter: {
        extension: {regex: "/(jpg)|(png)|(jpeg)/"}, 
        absolutePath: {regex: $directory}}
    ) {
      edges {
        node {
          name
          id
          relativePath
          base
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          publicURL
        }
      }
    }
  }
`

export default SinglePhotoGallery